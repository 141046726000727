import { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePost, usePut, useDel } from 'http/useInnovit';
import { rqDel, rqPost } from 'http/apiRoutes';
import { ProductCardView } from 'components/ui/ProductCardView';
import * as yup from 'yup';
import {
  FormAccordionSummary,
  FormBox,
  FormBoxElevated,
} from 'components/form';
import useWindowsDimension from 'functions/useWindowsDimension';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {
  Accordion,
  AccordionDetails,
  Button,
  Divider,
  Grid,
  Box,
  List,
  ListItem,
  Paper,
  Stack,
  Switch,
  Typography,
  IconButton,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import ImageUploader from 'components/ui/ImageUploader';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import {
  ICompanyViewModel,
  IDepartmentViewModel,
  ThirdPartEnum,
} from '../../Interfaces/ICompaniesConfig';
import ConvertBase64StringToFileModel from 'functions/ConvertBase64StringToFileModel';
import {
  EnumCompanyType,
  EnumObjectType,
  EnumRole,
  IAddressViewModel,
  MissingPhoto,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import { IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { formatPhoneNumber } from 'functions/FormatPhoneNumber';
import { SearchFilter } from 'components/ui/SearchFilter';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useAtom } from 'jotai';
import { ICompanyProductViewModel } from '../../Interfaces/ICompanyProductConfig';
import {
  IEmployeeAddViewModel,
  IEmployeeInterface,
  IEmployeeUpdateViewModel,
  IEmployeeViewModel,
} from 'Interfaces/IEmployeeConfig';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import {
  ConvertProductToCompanyProduct,
  ConvertStorageProductToCompanyProduct,
} from 'functions/Convert';
import userAtom from 'data/atoms/userAtom';
import { useTranslation } from 'react-i18next';
import { TextStyle } from 'data/FontStyle';

var _ = require('lodash');

const phoneRegex = /^\+[0-9]{2}(?=(?:\s*\d){8})([\d\s]*)$/;
const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(|[A-åÅØøÆæ0-9 ]{2,50})$/, 'Between 2-50 characters')
    .required('Name is required'),
  email: yup.string().email().required('Email is required'),
  // phone: yup.string().matches(/^(|[0-9]{8,16})$/, "Between 8-16 characters"),
  //webPage: yup.string().notRequired(),
  // .test(
  //   'test-webPage', // Name of the test
  //   'Enter a valid webPage URL starting with www.', // Error message
  //   (value) => !value || /^www\.[\w-]+\.[a-zA-Z]{2,}$/.test(value) // Test function
  // ),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(phoneRegex, 'Enter a valid phone number'),
});

interface FormErrors {
  name?: string;
  email?: string;
  phone?: string;
}
const validateFieldWithSchema = async (
  field: keyof IEmployeeInterface,
  value: any
) => {
  try {
    await schema.validateAt(field, { [field]: value });
    return true;
  } catch {
    return false;
  }
};
const isButtonDisabled = async (state: IEmployeeInterface) => {
  const isEmailValid = await validateFieldWithSchema('email', state.email);
  const isNameValid = await validateFieldWithSchema('name', state.name);
  const isPhoneValid = await validateFieldWithSchema('phone', state.phone);

  const isFormValid =
    isNameValid &&
    isEmailValid &&
    isPhoneValid &&
    state.departmentId.length >= 20;
  return !isFormValid;
};

const initialState: IEmployeeInterface = {
  // employee data
  id: 'new',
  creatorId: '',
  name: '',
  phone: '+47',
  email: '',
  departmentId: '',
  department: {
    id: 'new',
    name: '',
    companyId: '',
    contactId: 'new',
    contact: {} as IEmployeeViewModel,
    phone: '',
    email: '',
    mainAddress: {
      street: '',
      area: '',
      postalCode: '',
      city: '',
      country: '',
      postBox: '',
    } as IAddressViewModel,
    deliveryAddress: {
      street: '',
      area: '',
      postalCode: '',
      city: '',
      country: '',
      postBox: '',
    } as IAddressViewModel,
    canDelete: false,
    isDirty: false,
    isActive: false,
    isDefault: false,
  } as IDepartmentViewModel,
  address: {
    street: '',
    area: '',
    postalCode: '',
    city: '',
    country: '',
    postBox: '',
  } as IAddressViewModel,
  role: EnumRole.Employee,
  primaryContact: false,
  globalAdministrator: false,
  isOnboardingEnabled: false,
  budget: 0,
  title: '',
  url: '',
  fileModel: null,
  workRoleId: null,
  workRole: {} as IWorkRoleViewModel,
  companyProducts: [] as ICompanyProductViewModel[],
  productCatalog: [] as ICompanyProductViewModel[], // for selection
  storageProducts: [] as ICompanyProductViewModel[], // for selection
  unassignedCompanyProducts: [] as ICompanyProductViewModel[],
  legacyId: 0,
  thirdPartyId: '',
  thirdParty: ThirdPartEnum.None,
  isValid: false,
  isDirty: false,
};

type Action =
  | { type: 'SET_DIRTY'; payload: boolean }
  | { type: 'RESET_FORM' }
  | { type: 'SET_EMPLOYEE'; payload: IEmployeeViewModel }
  | { type: 'UPDATE_FIELD'; field: keyof IEmployeeViewModel; value: any }
  | { type: 'VALIDATE' }
  | { type: 'UPDATE_ADDRESS_FIELD'; field: keyof IAddressViewModel; value: any }
  | { type: 'UPDATE_DEPARTMENT'; value: IDepartmentViewModel }
  | { type: 'CHANGE_WORK_ROLE'; payload: IWorkRoleViewModel }
  | { type: 'CHANGE_BUDGET'; payload: number }
  | { type: 'TOGGLE_PRIMARY_CONTACT'; payload: boolean }
  | { type: 'TOGGLE_GLOBAL_ADMINISTRATOR'; payload: boolean }
  | { type: 'TOGGLE_ONBOARDING_ENABLED'; payload: boolean }
  | { type: 'ADD_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'UPDATE_STORAGE_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'REMOVE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'SET_COMPANY_PRODUCT'; payload: ICompanyProductViewModel[] }
  | { type: 'UPDATE_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | { type: 'ADD_UNASSIGN_COMPANY_PRODUCT'; payload: ICompanyProductViewModel }
  | {
      type: 'REMOVE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | {
      type: 'UPDATE_UNASSIGN_COMPANY_PRODUCT';
      payload: ICompanyProductViewModel;
    }
  | { type: 'SET_STATE_AFTER_PUT_EMPLOYEE'; payload: IEmployeeViewModel };

const reducer = (
  state: IEmployeeInterface,
  action: Action
): IEmployeeInterface => {
  switch (action.type) {
    case 'SET_DIRTY':
      return {
        ...state,
        isDirty: action.payload,
      };
    case 'UPDATE_FIELD':
      if (action.field === 'phone') {
        return {
          ...state,
          [action.field]: formatPhoneNumber(action.value),
        };
      }
      return { ...state, [action.field]: action.value };
    case 'VALIDATE':
      try {
        schema.validateSync(state, { abortEarly: false });
        return { ...state, isValid: true };
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          // Log the entire error object
          console.log('Validation Error: ', error);

          // Log specific details
          console.log('Errors: ', error.errors); // Array of error messages
          console.log('Inner Errors: ', error.inner); // Detailed error objects for each field

          // If you want to log more specific details for each field
          error.inner.forEach((err) => {
            console.log(err.path, err.message);
          });
        } else {
          // If the error is not a yup.ValidationError, log it
          console.log('Unexpected Error: ', error);
        }

        return { ...state, isValid: false };
      }
    case 'UPDATE_ADDRESS_FIELD':
      if (state.address === null) {
        // If address is null, create a new address object and update the field
        return {
          ...state,
          address: {
            ...({} as IAddressViewModel),
            [action.field]: action.value,
          },
        };
      } else {
        // If address exists, update the field within the existing address object
        return {
          ...state,
          address: {
            ...state.address,
            [action.field]: action.value,
          },
        };
      }
    case 'UPDATE_DEPARTMENT':
      return {
        ...state,
        department: action.value,
        departmentId: action.value.id,
      };
    case 'ADD_PRODUCT':
      //console.log('ADD_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: [...state.productCatalog, action.payload],
      };
    case 'REMOVE_PRODUCT':
      // console.log('REMOVE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_PRODUCT':
      // console.log('UPDATE_PRODUCT', action.payload);
      return {
        ...state,
        productCatalog: state.productCatalog.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_STORAGE_PRODUCT':
      // console.log('ADD_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: [...state.storageProducts, action.payload],
      };
    case 'REMOVE_STORAGE_PRODUCT':
      // console.log('REMOVE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_STORAGE_PRODUCT':
      // console.log('UPDATE_STORAGE_PRODUCT', action.payload);
      return {
        ...state,
        storageProducts: state.storageProducts.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: [...state.companyProducts, action.payload],
      };
    case 'REMOVE_COMPANY_PRODUCT':
      // console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'SET_COMPANY_PRODUCT':
      console.log('employeeform.tsx, SET_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: action.payload,
      };
    case 'UPDATE_COMPANY_PRODUCT':
      // console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        companyProducts: state.companyProducts.map((product) =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case 'ADD_UNASSIGN_COMPANY_PRODUCT':
      // console.log('ADD_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: [
          ...state.unassignedCompanyProducts,
          action.payload,
        ],
      };
    case 'REMOVE_UNASSIGN_COMPANY_PRODUCT':
      // console.log('REMOVE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.filter(
          (product) => product.id !== action.payload.id
        ),
      };
    case 'UPDATE_UNASSIGN_COMPANY_PRODUCT':
      // console.log('UPDATE_COMPANY_PRODUCT', action.payload);
      return {
        ...state,
        unassignedCompanyProducts: state.unassignedCompanyProducts.map(
          (product) =>
            product.id === action.payload.id ? action.payload : product
        ),
      };

    case 'CHANGE_WORK_ROLE':
      return {
        ...state,
        workRole: action.payload,
        workRoleId: action.payload.id,
      };
    case 'CHANGE_BUDGET':
      return {
        ...state,
        budget: action.payload,
      };
    case 'TOGGLE_PRIMARY_CONTACT':
      // console.log('TOGGLE_PRIMARY_CONTACT', action.payload);
      return {
        ...state,
        primaryContact: action.payload,
      };
    case 'TOGGLE_GLOBAL_ADMINISTRATOR':
      return {
        ...state,
        globalAdministrator: action.payload,
      };
    case 'TOGGLE_ONBOARDING_ENABLED':
      return { ...state, isOnboardingEnabled: action.payload };
    case 'RESET_FORM':
      return initialState;
    case 'SET_EMPLOYEE':
      console.log('SET_EMPLOYEE', action.payload);
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        phone: action.payload.phone,
        email: action.payload.email,
        departmentId: action.payload.departmentId,
        department: action.payload.department,
        address: action.payload.address,
        role: action.payload.role,
        primaryContact: action.payload.primaryContact,
        globalAdministrator: action.payload.globalAdministrator,
        budget: action.payload.budget,
        title: action.payload.title,
        url: action.payload.url,
        workRole: action.payload.workRole,
        workRoleId: action.payload.workRole?.id || '',
        companyProducts: action.payload.companyProducts,
      };
    case 'SET_STATE_AFTER_PUT_EMPLOYEE':
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        phone: action.payload.phone,
        email: action.payload.email,
        departmentId: action.payload.departmentId,
        department: action.payload.department,
        address: action.payload.address,
        role: action.payload.role,
        primaryContact: action.payload.primaryContact,
        globalAdministrator: action.payload.globalAdministrator,
        budget: action.payload.budget,
        title: action.payload.title,
        url: action.payload.url,
        workRole: action.payload.workRole,
        workRoleId: action.payload.workRole?.id || '',
        companyProducts: action.payload.companyProducts,
        productCatalog: [] as ICompanyProductViewModel[],
        storageProducts: [] as ICompanyProductViewModel[],
        unassignedCompanyProducts: [] as ICompanyProductViewModel[],
      };
    default:
      return state;
  }
};

type Props = {
  employee: IEmployeeViewModel;
  departments: IDepartmentViewModel[];
  objectDrawerList: objectArray[];
  company: ICompanyViewModel;
};

export const EmployeeForm = ({
  employee,
  departments,
  objectDrawerList,
  company,
}: Props) => {
  const { t } = useTranslation();
  /* INITIALIZATIONS */
  let navigate = useNavigate();
  const { height } = useWindowsDimension();
  const { companyId, employeeId } = useParams() as any | undefined;
  // Other local state variables
  const [isProductChanged, setIsProductChanged] = useState(false) as any;
  const [image, setImage] = useState(null) as any;
  const [base64String, setBase64String] = useState('') as any;
  const [imageUploaderKey, setImageUploaderKey] = useState(0);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isDisabled, setIsDisabled] = useState(true);
  const location = useLocation(); // Initialize location to get the current URL

  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [user] = useAtom(userAtom);

  // API CALLS

  const putEmployee = usePut(
    rqPost.employee(companyId, employeeId, true),
    true
  );
  const postEmployee = usePost(
    rqPost.employee(companyId, employeeId, true),
    true
  );
  const postEmployeeDeactivate = usePost(
    rqPost.employeeDeactivate(companyId, employeeId, true)
  );
  let productId: string = '';
  const deleteCompanyProduct = useDel(
    rqDel.companyProduct(employeeId, productId)
  );
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  /* HANDLSES */
  const validateField = async (
    field: keyof IEmployeeViewModel,
    value: string
  ) => {
    try {
      await schema.validateAt(field, { [field]: value });
      setErrors((prev) => ({ ...prev, [field]: '' }));
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        setErrors((prev) => ({ ...prev, [field]: error.message }));
      }
    }
  };
  type FormOrMouseEvent =
    | React.FormEvent<HTMLFormElement>
    | React.MouseEvent<HTMLDivElement, MouseEvent>;

  const handleChangeWorkRole = (
    event: FormOrMouseEvent,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.WorkRole:
        let object = selectedObject as IWorkRoleViewModel;
        dispatch({ type: 'CHANGE_WORK_ROLE', payload: object });
        break;
      default:
        break;
    }
  };
  const handleConfirm = (event: any) => {
    //console.log("delete");
    postEmployeeDeactivate.mutate(
      { id: employeeId },
      { onSuccess: navigate(`/companies/${companyId}/employees`) }
    );
  };
  const handleRemoveProduct = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    setIsProductChanged(true);

    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'REMOVE_PRODUCT', payload: companyProduct });

        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'REMOVE_UNASSIGN_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        break;
      case EnumObjectType.CompanyProduct:
        companyProduct.ownerId
          ? dispatch({
              type: 'UPDATE_COMPANY_PRODUCT',
              payload: {
                ...companyProduct,
                ownerId: null,
                owner: null,
                placement: 0,
              },
            })
          : dispatch({
              type: 'UPDATE_COMPANY_PRODUCT',
              payload: {
                ...companyProduct,
                ownerId: employeeId,
                // owner: employee,
                placement: 1,
              },
            });
        break;
      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'REMOVE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        break;
    }
  };
  const handleChange = (
    event: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    event.preventDefault();
    setIsProductChanged(true);
    switch (objectType) {
      case EnumObjectType.Product:
        let product = ConvertProductToCompanyProduct(
          selectedObject as IBillingProductViewModel
        );
        // Check if onboarding is enabled and paymentCycle is 0
        if (
          state.isOnboardingEnabled &&
          product.product &&
          product.product.paymentCycle === 0
        ) {
          // product.retailPrice = 0; // Set the price to 0 only if onboarding is enabled
          product.isBillable = false;
        }
        dispatch({
          type: 'ADD_PRODUCT',
          payload: product as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.CompanyProduct:
        dispatch({
          type: 'ADD_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'ADD_UNASSIGN_COMPANY_PRODUCT',
          payload: selectedObject as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.StorageProduct:
        // convert to company product
        let storageProduct = ConvertStorageProductToCompanyProduct(
          selectedObject as IStorageProductViewModel
        );
        dispatch({
          type: 'ADD_STORAGE_PRODUCT',
          payload: storageProduct as ICompanyProductViewModel,
        });
        break;
      case EnumObjectType.Package:
        let packageProducts = selectedObject.productPackageProducts.map(
          (packageProduct: ICompanyProductViewModel) => {
            let newProduct = { ...packageProduct };

            // Adding the 'retailPrice' from the product to our new object
            newProduct.retailPrice = packageProduct.product.retailPrice;
            newProduct.serviceTag = '';
            return newProduct;
          }
        );

        packageProducts.forEach((product: ICompanyProductViewModel) => {
          dispatch({
            type: 'ADD_PRODUCT',
            payload: product as ICompanyProductViewModel,
          });
        });

        break;
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: 'UPDATE_FIELD',
      field: name as keyof IEmployeeViewModel,
      value: value,
    });
    dispatch({ type: 'VALIDATE' });
    dispatch({
      type: 'SET_DIRTY',
      payload: true,
    });
  };

  const handleFieldChangePhone = (value: string, info: MuiTelInputInfo) => {
    dispatch({
      type: 'UPDATE_FIELD',
      field: 'phone',
      value: value,
    });
    validateField('phone', value);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    validateField(name as keyof IEmployeeViewModel, value);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select(); // Select the entire value
  };
  const handleChangeProductData = (
    companyProduct: ICompanyProductViewModel,
    objectType: EnumObjectType
  ) => {
    console.log('handleChangeProductData', objectType, companyProduct);
    switch (objectType) {
      case EnumObjectType.Product:
        dispatch({ type: 'UPDATE_PRODUCT', payload: companyProduct });
        break;
      case EnumObjectType.CompanyProduct:
        dispatch({ type: 'UPDATE_COMPANY_PRODUCT', payload: companyProduct });
        break;
      case EnumObjectType.unassignedCompanyProduct:
        dispatch({
          type: 'UPDATE_UNASSIGN_COMPANY_PRODUCT',
          payload: companyProduct,
        });
        break;
      case EnumObjectType.StorageProduct:
        dispatch({
          type: 'UPDATE_STORAGE_PRODUCT',
          payload: companyProduct,
        });
        break;
    }
    console.log('handleChangeProductData - x', state.productCatalog);
  };
  interface OHjsxProps {
    onOff: boolean;
    dispatch: React.Dispatch<Action>;
  }
  const PrimaryContactSwitch: React.FC<OHjsxProps> = ({ onOff, dispatch }) => {
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'TOGGLE_PRIMARY_CONTACT', payload: e.target.checked });
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {onOff
              ? t('switch.yes').toUpperCase()
              : t('switch.no').toUpperCase()}
          </Typography>

          <Switch
            color={onOff ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: onOff ? 'secondary.light' : 'primary.text',
              },
              '& .MuiSwitch-track': {
                backgroundColor: onOff
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)',
              },
            }}
            checked={onOff}
            onChange={handleToggle}
            name='PRIMARY CONTACT'
          />
        </Stack>
      </Grid>
    );
  };
  const GlobalAdministratorSwitch: React.FC<OHjsxProps> = ({
    onOff,
    dispatch,
  }) => {
    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'TOGGLE_GLOBAL_ADMINISTRATOR',
        payload: e.target.checked,
      });
    };
    return (
      <Grid item sx={{ m: 0, p: 0 }}>
        <Stack direction='row'>
          <Typography
            sx={{
              m: 0,
              p: 2.2,
              pr: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 14,
              color: 'primary.text',
            }}
          >
            {onOff
              ? t('switch.yes').toUpperCase()
              : t('switch.no').toUpperCase()}
          </Typography>

          <Switch
            color={onOff ? 'secondary' : 'primary'}
            sx={{
              mt: 1,
              mr: 1,
              '& .MuiSwitch-thumb': {
                backgroundColor: onOff ? 'secondary.light' : 'primary.text',
              },
              '& .MuiSwitch-track': {
                backgroundColor: onOff
                  ? 'rgba(0, 0, 0, 0.7)'
                  : 'rgba(0, 0, 255, 0.3)',
              },
            }}
            checked={onOff}
            onChange={handleToggle}
            name='GLOBAL ADMINISTRATOR'
          />
        </Stack>
      </Grid>
    );
  };
  const setFormDirty = () => {
    console.log('setFormDirty');
  };
  const handleDepartmentChange = (e: any) => {
    const selectedValue = e.target.value;
    if (selectedValue.toString() === '0') {
      return;
    } else {
      const selectedDepartmentId = selectedValue.toString();
      const selectedDepartment = departments.find(
        (dep: IDepartmentViewModel) => dep.id === selectedDepartmentId
      );
      if (selectedDepartment) {
        dispatch({
          type: 'UPDATE_DEPARTMENT',
          value: selectedDepartment,
        });
      } else {
        console.log('No department selected', selectedValue);
      }
    }

    // Check for a valid value
    if (selectedValue === undefined || selectedValue === null) {
      console.error('Invalid value received from Select');
      return;
    }

    const selectedDepartmentId = selectedValue.toString();
    const selectedDepartment = departments.find(
      (dep: IDepartmentViewModel) => dep.id === selectedDepartmentId
    );

    if (selectedDepartment) {
      dispatch({
        type: 'UPDATE_DEPARTMENT',
        value: selectedDepartment,
      });
    } else {
      console.log('No department selected', selectedValue);
    }
  };
  // useEffects

  // set employee form
  useEffect(() => {
    if (employee) {
      // console.log('set employee, employee', employee);
      dispatch({ type: 'SET_EMPLOYEE', payload: employee });
      if (employee.departmentId && departments) {
        let _department = departments.find(
          (dep: IDepartmentViewModel) => dep.id === employee.departmentId
        );
        _department &&
          dispatch({ type: 'UPDATE_DEPARTMENT', value: _department });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  useEffect(() => {
    if (employeeId === 'new') {
      dispatch({ type: 'RESET_FORM' });
    }
  }, [employeeId]);

  useEffect(() => {
    console.log('employeeForm state', state);
  }, [state]);

  useEffect(() => {
    const checkIfButtonShouldBeDisabled = async () => {
      const result = await isButtonDisabled(state);
      setIsDisabled(result);
    };

    checkIfButtonShouldBeDisabled();
  }, [state]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    let fileModel = null;
    if (image) {
      fileModel = ConvertBase64StringToFileModel(base64String, image.name);
    }

    // check if it is a new employee
    if (state?.id === 'new') {
      let newEmployee: IEmployeeAddViewModel = {
        name: state.name,
        creatorId: user.id,
        phone: state.phone,
        email: state.email,
        departmentId: state.departmentId,
        address: state.address?.street ? state.address : null,
        role: state.role,
        primaryContact: state.primaryContact,
        globalAdministrator: state.globalAdministrator,
        budget: state.budget,
        title: state.title,
        fileModel: fileModel ? fileModel : null,
        companyProducts: state.companyProducts,
        productCatalog: state.productCatalog,
        storageProducts: state.storageProducts,
        unassignedCompanyProducts: state.unassignedCompanyProducts,
        workRoleId: state.workRoleId ? state.workRoleId : null,
        legacyId: state.legacyId,
        thirdPartyId: state.thirdPartyId,
        thirdParty: state.thirdParty,
      };
      console.log('newEmployee', newEmployee);

      postEmployee.mutate(newEmployee, {
        onSuccess: (res: any) => {
          setImage(null);
          setBase64String('');
          setImageUploaderKey((prevKey) => prevKey + 1);
          dispatch({ type: 'RESET_FORM' });

          // Check if the source is from the drawer
          const queryParams = new URLSearchParams(location.search);
          if (queryParams.get('source') === 'drawer') {
            navigate('/orders'); // Redirect to /orders
          } else {
            navigate('/employees'); // Or another appropriate path after manual creation
          }
        },
      });
    } else {
      let updateEmployee: IEmployeeUpdateViewModel = {
        id: state?.id ?? 'new',
        creatorId: user.id,
        name: state.name,
        phone: state.phone,
        email: state.email,
        departmentId: state.departmentId,
        department: state.department,
        address: state.address?.street ? state.address : null,
        role: state.role,
        primaryContact: state.primaryContact,
        globalAdministrator: state.globalAdministrator,
        budget: state.budget,
        title: state.title,
        fileModel: fileModel ? fileModel : null,
        companyProducts: state.companyProducts,
        productCatalog: state.productCatalog,
        storageProducts: state.storageProducts,
        unassignedCompanyProducts: state.unassignedCompanyProducts,
        workRoleId: state.workRoleId ? state.workRoleId : null,
      };
      console.log('updateEmployee', updateEmployee);
      putEmployee.mutate(updateEmployee, {
        onSuccess: (res: any) => {
          dispatch({ type: 'SET_STATE_AFTER_PUT_EMPLOYEE', payload: res });
          setImage(null);
          setBase64String('');

          // Check if the source is from the drawer
          const queryParams = new URLSearchParams(location.search);
          if (queryParams.get('source') === 'drawer') {
            navigate('/employees'); // Redirect to /orders
          } else {
            navigate('/employees'); // Or another appropriate path after manual update
          }
        },
      });
    }
  };

  return (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
      }}
    >
      {/*   HEADER */}
      <Grid item xs={12}>
        <SearchFilter
          Icon={FeedRoundedIcon}
          Header={t('company.employees.detailsHeader')}
          filterValue={null}
          handleFilterChange={null}
          handleSearch={null}
          handleNew={null}
          selectList={null}
        />
      </Grid>

      <List
        sx={{
          width: '100%',
          height: Math.ceil(height - 232),
          p: 1,
          pt: 0,
          overflow: 'auto',
          backgroundColor: 'primary.main',
        }}
      >
        {/*   Employee Data */}
        <Stack key={1} direction='row'>
          <Grid item sx={{ m: 1, width: 192 }}>
            <Stack direction='column'>
              <Typography sx={[TextStyle.info, { p: 1 }]}>
                {t('company.employees.profilePicture')}
              </Typography>
              {state && (
                <ImageUploader
                  key={imageUploaderKey}
                  width={192}
                  height={192}
                  previewImage={state?.url || MissingPhoto.EMPLOYEE_URL}
                  setImage={setImage}
                  setBase64String={(str) => {
                    if (typeof str === 'string' || str === null) {
                      setBase64String(str);
                    }
                  }}
                  base64String={base64String}
                  setFormDirty={setFormDirty}
                />
              )}
              {company?.companyType === EnumCompanyType.APPOWNER && (
                <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                  <Grid item xs={12}>
                    {state.workRole && state.workRole?.id ? (
                      <Grid
                        container
                        display={'flex'}
                        flexDirection={'row'}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: 'primary.main',
                        }}
                      >
                        <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                          <Typography
                            sx={[TextStyle.info, { p: 1, width: '50%' }]}
                          >
                            {t('company.employees.workRole')}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            justifyContent: 'flex-end',
                            display: 'flex',
                          }}
                        >
                          <Stack direction='row'>
                            <Box sx={[TextStyle.buttonBox, { p: 0 }]}>
                              <IconButton
                                sx={{ m: 0, p: 0 }}
                                onClick={(e) => {
                                  dispatch({
                                    type: 'CHANGE_WORK_ROLE',
                                    payload: {} as IWorkRoleViewModel,
                                  });
                                }}
                              >
                                <DeleteForeverRoundedIcon />
                              </IconButton>
                            </Box>
                            <ObjectDrawer
                              buttonContext={t(
                                'company.employees.selectWorkRole'
                              )}
                              buttonIcon={<ChangeCircleIcon />}
                              objectList={
                                !!objectDrawerList ? objectDrawerList : []
                              }
                              objectType={[EnumObjectType.WorkRole]}
                              handleChange={handleChangeWorkRole}
                              buttonHeight={32}
                              buttonWidth={32}
                            />
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            m: 0,
                            p: 0,
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Typography>{state.workRole.title}</Typography>
                          <Typography>
                            {state.workRole.onSiteHourlyRate}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                          <Divider sx={{ backgroundColor: 'primary.text' }} />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                        <Typography
                          sx={{
                            m: 0,
                            p: 1,
                            textAlign: 'left',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontWeight: 'normal',
                            fontSize: 14,
                            color: 'primary.text',
                          }}
                        >
                          {t('company.employees.workRole')}
                        </Typography>

                        <ObjectDrawer
                          buttonContext={t('company.employees.selectWorkRole')}
                          buttonIcon={null}
                          objectList={
                            !!objectDrawerList ? objectDrawerList : []
                          }
                          objectType={[EnumObjectType.WorkRole]}
                          handleChange={handleChangeWorkRole}
                          buttonHeight={60}
                          buttonWidth={'100%'}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Grid>
          <Grid
            container
            sx={{
              p: 2,
              mt: 0.0,
            }}
            rowSpacing={1}
            columnSpacing={2}
          >
            <Grid item xs={7}>
              <TextField
                name='name'
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                label={t('company.details.name')}
                value={state.name}
                onChange={handleFieldChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>

            <Grid item xs={5}>
              <MuiTelInput
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                continents={['EU']}
                preferredCountries={['NO', 'SE', 'DK', 'DE', 'GB']}
                defaultCountry='NO'
                forceCallingCode={true}
                label={t('company.employees.phone')}
                variant='standard'
                value={state.phone}
                onChange={handleFieldChangePhone}
                onFocus={handleFocus}
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                name='email'
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                label={t('company.details.email')}
                value={state.email}
                onChange={handleFieldChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                label={t('company.employees.title')}
                value={state.title.toLowerCase()}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'title',
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={{
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                label={t('company.employees.budget')}
                value={state.budget}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'budget',
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                  color: 'primary.text',
                }}
              >
                {t('company.employees.appRole')}
              </Typography>
              <Select
                sx={{
                  width: '100%',
                  p: 0,
                  mt: -0.5,
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                labelId='role'
                id='role'
                variant='standard'
                value={state.role}
                label='role'
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'role',
                    value: e.target.value,
                  })
                }
              >
                <MenuItem value={0}>
                  {t('company.employees.appRoleListManager')}
                </MenuItem>
                <MenuItem value={1}>
                  {t('company.employees.appRoleListDepartmentManager')}
                </MenuItem>
                <MenuItem value={2}>
                  {t('company.employees.appRoleListEmployee')}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={5}>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                  color: 'primary.text',
                }}
              >
                {t('company.employees.department')}
              </Typography>
              <Select
                sx={{ width: '100%', p: 0, mt: -0.5 }}
                labelId='department'
                id='department'
                variant='standard'
                value={state.department?.id || 0}
                label='department'
                onChange={handleDepartmentChange}
              >
                <MenuItem key={0} value={0}>
                  {t('company.employees.chooseDepartment')}
                </MenuItem>
                {departments &&
                  departments.map((department: IDepartmentViewModel) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            {/*   DEPARTMENT */}
            <Grid
              item
              xs={12}
              sx={{
                mb: -2,
              }}
            >
              <Box sx={{ alignSelf: 'center', display: 'flex' }}>
                <HomeRoundedIcon sx={{ fontSize: 32 }} />
                <Typography
                  sx={{
                    ml: 1,
                    mt: 1,

                    flexGrow: 1,
                    textTransform: 'uppercase',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'bold',
                    fontSize: 14,
                    letterSpacing: 2,
                  }}
                >
                  {t('company.employees.departmentInformation')}
                </Typography>
              </Box>

              <Grid
                container
                sx={{
                  p: 0,
                  pt: 1,
                }}
                direction='row'
              >
                <Grid container display={'flex'} flexDirection={'row'}>
                  <Grid item xs={5} sx={{ pr: 2, height: 30 }}>
                    {state.department?.mainAddress?.street}
                    <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                  </Grid>
                  <Grid item xs={3} sx={{ pr: 2, height: 30 }}>
                    {state.department?.mainAddress?.postalCode}
                    <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                  </Grid>
                  <Grid item xs={4} sx={{ pr: 2, height: 30 }}>
                    {state.department?.mainAddress?.area}
                    <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                  </Grid>
                  <Grid item xs={5} sx={{ pr: 2, pt: 1, height: 30 }}>
                    {state.department?.mainAddress?.city}
                    <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                  </Grid>
                  <Grid item xs={3} sx={{ pr: 2, pt: 1, height: 30 }}>
                    {state.department?.mainAddress?.country}
                    <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      pr: 2,
                      pt: 1,
                      height: 30,
                      color: `${
                        !state.department?.mainAddress?.postBox
                          ? 'text.disabled'
                          : 'text.primary'
                      }`,
                    }}
                  >
                    {state.department?.mainAddress?.postBox
                      ? state.department?.mainAddress?.postBox
                      : t('company.employees.noPostbox')}
                    <Divider sx={{ mt: 0 }} variant='dividerMenu' />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        {/* COMPANY PRODUCTS */}
        <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
          <FormBox
            Icon={DevicesOtherRoundedIcon}
            title={t('company.employees.products')}
            FormBTN={
              <Grid sx={{ display: 'flex' }}>
                <Grid display={'flex'} sx={{ mr: 2 }}>
                  <Switch
                    checked={state.isOnboardingEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: 'TOGGLE_ONBOARDING_ENABLED',
                        payload: e.target.checked,
                      })
                    }
                    name='enableOnboarding'
                    color='warning'
                    sx={{}}
                  />
                  <Typography
                    sx={{
                      m: 0,
                      pt: 1,
                      // outline: '1px solid red',
                      pr: 0,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      fontSize: 14,
                      color: 'warning.text',
                    }}
                  >
                    {t('switch.onboarding')}
                  </Typography>
                </Grid>
                <Grid sx={{ position: 'relative', top: 7 }}>
                  {state.isOnboardingEnabled ? (
                    <ObjectDrawer
                      buttonContext={t('company.employees.addFirstTimeProduct')}
                      buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
                      objectList={objectDrawerList || []}
                      objectType={[
                        EnumObjectType.Product,
                        EnumObjectType.StorageProduct,
                        EnumObjectType.unassignedCompanyProduct,
                        EnumObjectType.Package,
                      ]}
                      handleChange={handleChange}
                      buttonHeight={26}
                      buttonWidth={26}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 26,
                        height: 26,
                        backgroundColor: 'primary.light',
                        display: 'flex', // <-- Set this to flex
                        justifyContent: 'center', // <-- This centers children horizontally
                        alignItems: 'center', // <-- This centers children vertically
                        borderRadius: 2,
                        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                      }}
                    >
                      <IconButton
                        disabled={true}
                        sx={{
                          p: 0,
                          m: 0,
                          width: 26,
                          height: 26,
                        }}
                      >
                        <AddRoundedIcon sx={{ fontSize: 26 }} />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
            }
          />
        </Grid>
        {/* PRODUCTS */}
        <Grid item xs={12} sx={{ mb: 2 }}>
          <List
            sx={{
              width: '100%',
              overflow: 'auto',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: 3,
              // ... other styles
            }}
          >
            {state?.companyProducts &&
              state.companyProducts?.map(
                (companyProduct: ICompanyProductViewModel, index: number) => {
                  return !!companyProduct ? (
                    <ListItem
                      sx={{ mb: 1, p: 0, pl: 1, pr: 1 }}
                      key={index * 10}
                    >
                      <ProductCardView
                        companyProduct={companyProduct}
                        location={EnumObjectType.CompanyEmployees}
                        objectType={EnumObjectType.CompanyProduct}
                        handleRemoveProduct={handleRemoveProduct}
                        handleChange={handleChangeProductData}
                      />
                    </ListItem>
                  ) : null;
                }
              )}
            {state?.unassignedCompanyProducts?.map(
              (companyProduct: ICompanyProductViewModel, index: number) => {
                return !!companyProduct ? (
                  <ListItem sx={{ mb: 1, p: 0 }} key={index * 100}>
                    <ProductCardView
                      companyProduct={companyProduct}
                      location={EnumObjectType.CompanyEmployees}
                      objectType={EnumObjectType.unassignedCompanyProduct}
                      handleRemoveProduct={handleRemoveProduct}
                      handleChange={handleChangeProductData}
                    />
                  </ListItem>
                ) : null;
              }
            )}
            {state?.storageProducts?.map(
              (companyProduct: ICompanyProductViewModel, index: number) => {
                return !!companyProduct ? (
                  <ListItem key={index * 1000} sx={{ mb: 1, p: 0 }}>
                    <ProductCardView
                      companyProduct={companyProduct}
                      location={EnumObjectType.CompanyEmployees}
                      objectType={EnumObjectType.StorageProduct}
                      handleRemoveProduct={handleRemoveProduct}
                      handleChange={handleChangeProductData}
                    />
                  </ListItem>
                ) : null;
              }
            )}
            {state?.productCatalog?.map(
              (companyProduct: ICompanyProductViewModel, index: number) => {
                return !!companyProduct ? (
                  <ListItem sx={{ mb: 1, p: 0 }} key={index * 10000}>
                    <ProductCardView
                      companyProduct={companyProduct}
                      location={EnumObjectType.CompanyEmployees}
                      objectType={EnumObjectType.Product}
                      handleRemoveProduct={handleRemoveProduct}
                      handleChange={handleChangeProductData}
                    />
                  </ListItem>
                ) : null;
              }
            )}
          </List>
        </Grid>
        {/*   HOME OFFICE ADDRESS */}
        <Grid
          key={3}
          item
          xs={12}
          sx={{
            mb: 2,
            border: 0,
            borderRadius: 2,
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <Accordion
            disableGutters
            sx={{
              backgroundColor: 'transparent',
              border: 0,
            }}
          >
            <FormAccordionSummary
              Icon={HomeRoundedIcon}
              title={t('address.homeOfficeAddress')}
            />
            <AccordionDetails>
              <TextField
                sx={{
                  width: '50%',
                  p: 1,
                  m: 0,
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                label={t('address.street')}
                value={state.address?.street || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    field: 'street',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{
                  width: '20%',
                  p: 1,
                  m: 0,
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                label={t('address.zip')}
                value={state.address?.postalCode || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    field: 'postalCode',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{
                  width: '30%',
                  p: 1,
                  m: 0,
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                label={t('address.area')}
                value={state.address?.area || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    field: 'area',
                    value: e.target.value,
                  })
                }
              />
              <TextField
                sx={{
                  width: '40%',
                  p: 1,
                  m: 0,
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                label={t('address.city')}
                value={state.address?.city || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    field: 'city',
                    value: e.target.value,
                  })
                }
              />

              <Select
                sx={{
                  width: '30%',
                  p: 0,
                  mt: 3,
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                labelId='country'
                id='country'
                variant='standard'
                value={state.address?.country || 'Norway'}
                label={t('address.country')}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    field: 'country',
                    value: e.target.value,
                  })
                }
              >
                <MenuItem value={0}>Norway</MenuItem>
                <MenuItem value={1}>Sweden</MenuItem>
                <MenuItem value={2}>Denmark</MenuItem>
                <MenuItem value={3}>Germany</MenuItem>
                <MenuItem value={4}>England</MenuItem>
              </Select>
              <TextField
                sx={{
                  width: '30%',
                  p: 1,
                  m: 0,
                  '& .Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
                label={t('address.postbox')}
                value={state.address?.postBox || ''}
                onChange={(e) =>
                  dispatch({
                    type: 'UPDATE_ADDRESS_FIELD',
                    field: 'postBox',
                    value: e.target.value,
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        {/*   PRIMARY CONTACT */}
        <Grid key={4} item xs={12} sx={{ mb: 2 }}>
          <FormBoxElevated
            Icon={ContactPhoneIcon}
            title={t('company.employees.primaryContact')}
            FormJSX={() => (
              <PrimaryContactSwitch
                onOff={state.primaryContact}
                dispatch={dispatch}
              />
            )}
          />
        </Grid>
        {/*   GLOBAL ADMINISTRATOR */}
        <Grid key={5} item xs={12} sx={{ mb: 2 }}>
          <FormBoxElevated
            Icon={ContactPhoneIcon}
            title={t('company.employees.globalAdministrator')}
            FormJSX={() => (
              <GlobalAdministratorSwitch
                onOff={state.globalAdministrator}
                dispatch={dispatch}
              />
            )}
          />
        </Grid>
      </List>
      {/*   Submit Button */}

      <Grid item xs={12} sx={{ m: 0, display: 'flex', flexDirection: 'row' }}>
        {employeeId !== 'new' && (
          <Button
            variant='contained'
            onClick={() => setDeleteConfirm(!deleteConfirm ? true : false)}
          >
            {postEmployeeDeactivate.isLoading
              ? t('button.deleting')
              : t('button.delete')}
          </Button>
        )}
        {!!deleteConfirm === true && (
          <Button variant='contained' color='secondary' onClick={handleConfirm}>
            {t('button.confirmDelete')}
          </Button>
        )}
        <Grid sx={{ flexGrow: 1 }}></Grid>
        {employeeId !== 'new' && employeeId !== undefined && (
          <Button
            onClick={(e) => {
              onSubmit(e);
            }}
            variant='contained'
            //  disabled={isProductChanged ? false : !isDirty ? true : false}
          >
            {putEmployee.isLoading ? t('button.updating') : t('button.update')}
          </Button>
        )}
        {employeeId === 'new' && (
          <Button
            type='submit'
            variant='contained'
            disabled={isDisabled}
            onClick={onSubmit}
          >
            {postEmployee.isLoading ? t('button.creating') : t('button.create')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
