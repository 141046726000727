import { Grid, Stack, Typography } from '@mui/material';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import dayjs from 'dayjs';
import { IBillingDetailViewModel } from 'Interfaces/BillingConfig';
import React from 'react';

type Props = {
  billingDetail: IBillingDetailViewModel;
};

// STYLE
const sxHeader = {
  pl: 1,
  pt: 1,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: 16,
};
const sxSubHeader = {
  pl: 1,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  fontSize: 14,
  color: 'primary.text',
};
const sxSubValue = {
  pl: 1,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: 14,
  color: 'secondary.text',
};
const sxHeaderProduct = {
  pl: 1,
  mt: -0.4,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: 14,
  color: 'primary.text',
};

const sxHeaderFeature = {
  pl: 1,
  width: '25&',
  letterSpacing: 1,
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'Normal',
  textTransform: 'capitalize',
  fontSize: 12,
  color: 'primary.text',
};

export const SingleBillingDetailView = ({ billingDetail }: Props) => {
  let totalBillingValue = 0;
  if (billingDetail.support != null) {
    totalBillingValue =
      billingDetail.support.nightBillableValue +
      billingDetail.support.workBillableValue +
      billingDetail.support.midDayBillableValue +
      billingDetail.support.eveningBillableValue;
  }
  if (billingDetail.companyProduct != null) {
    totalBillingValue =
      billingDetail.companyProduct.retailPrice *
      billingDetail.companyProduct.quantity;
  }
  if (billingDetail.agreementProduct != null) {
    totalBillingValue =
      billingDetail.agreementProduct.retailPrice *
      billingDetail.agreementProduct.quantity;
  }
  if (billingDetail.product != null) {
    totalBillingValue =
      billingDetail.product.retailPrice *
      billingDetail.product.originalQuantity;
  }
  //console.log('billingDetail.agreementProduct', billingDetail);
  return billingDetail && billingDetail.support != null ? (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
        mb: 1,
        backgroundColor: 'primary.main',
        borderRadius: 2,
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
        borderLeft: 8,
        borderRight: 3,
        borderColor: 'info.light',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Stack direction='row' spacing={0} sx={{}}>
        <Grid
          item
          sx={{
            width: 80,
            height: 80,
          }}
        >
          <img
            src={MissingPhoto.BILLING_SUPPORT_URL}
            style={{
              marginLeft: 5,
              width: 80,
              height: 80,
              borderRadius: 3,
              objectFit: 'scale-down',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            }}
            alt={'logo'}
          />
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sx={{}}>
            <Typography sx={sxHeader}>{'SUPPORT'}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Grid
              item
              xs={1}
              sx={{
                width: 48,
                height: 48,
                backgroundColor: 'primary.light',
              }}
            >
              <img
                src={MissingPhoto.BILLING_SUPPORT_URL}
                style={{
                  marginLeft: 5,
                  width: 48,
                  height: 48,
                  borderRadius: 3,
                  objectFit: 'scale-down',
                  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                }}
                alt={'logo'}
              />
            </Grid>
            <Grid item xs={11} sx={{}}>
              <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                <Grid item xs={2} sx={{}}>
                  <Typography sx={sxSubHeader}>{'night'}</Typography>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography sx={sxSubValue}>
                    {billingDetail.support.nightBillableValue.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography sx={sxSubHeader}>{'Workday'}</Typography>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography sx={sxSubValue}>
                    {billingDetail.support.workBillableValue.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      pl: 1,
                      pr: 2,
                      width: '100&',
                      letterSpacing: 2,
                      textAlign: 'right',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      fontSize: 16,
                      color: 'primary.text',
                    }}
                  >
                    {'Total'}
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography sx={sxSubHeader}>{'midDay'}</Typography>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography sx={sxSubValue}>
                    {billingDetail.support.midDayBillableValue.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography sx={sxSubHeader}>{'evening'}</Typography>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <Typography sx={sxSubValue}>
                    {billingDetail.support.eveningBillableValue.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      pl: 1,
                      pr: 2,
                      width: '100&',
                      letterSpacing: 2,
                      textAlign: 'right',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      fontSize: 16,
                      color: 'secondary.text',
                    }}
                  >
                    {totalBillingValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  ) : billingDetail.companyProduct != null ? (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
        mb: 1,
        backgroundColor: 'primary.main',
        borderRadius: 2,
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
        borderLeft: 8,
        borderRight: 3,
        borderColor: 'secondary.light',
      }}
    >
      <img
        src={MissingPhoto.BILLING_PRODUCT_URL}
        style={{
          marginLeft: 5,
          width: 80,
          height: 80,
          borderRadius: 3,
          objectFit: 'scale-down',
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
        }}
        alt={'logo'}
      />

      <Grid item sx={{ flexGrow: 1 }}>
        <Grid item xs={12} sx={{}}>
          <Typography sx={sxHeader}>{'INVESTMENT'}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid
            item
            xs={1}
            sx={{
              width: 48,
              height: 48,
              backgroundColor: 'primary.light',
            }}
          >
            <img
              src={
                billingDetail.companyProduct.product?.url ||
                MissingPhoto.PRODUCT_URL
              }
              style={{
                marginLeft: 5,
                width: 48,
                height: 48,
                borderRadius: 3,
                objectFit: 'scale-down',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
              }}
              alt={'logo'}
            />
          </Grid>
          <Grid item xs={11} sx={{}}>
            <Grid item xs={12} sx={{}}>
              <Typography sx={sxHeaderProduct}>
                {billingDetail.companyProduct.product.name}
              </Typography>
            </Grid>
            <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
              <Grid item xs={8} sx={{}}>
                <Typography sx={sxHeaderFeature}>
                  {billingDetail?.companyProduct?.product.productType.name}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography
                  sx={{
                    pl: 1,
                    pr: 2,
                    mt: -1,
                    width: '100&',
                    letterSpacing: 2,
                    textAlign: 'right',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 16,
                    color: 'primary.text',
                  }}
                >
                  {'Total'}
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <Typography sx={sxHeaderFeature}>
                  {billingDetail?.companyProduct?.product.productType.name}
                </Typography>
              </Grid>
              <Grid item sx={{ flexGrow: 1 }}>
                <Typography
                  sx={{
                    pl: 1,
                    pr: 2,
                    width: '100&',
                    letterSpacing: 2,
                    textAlign: 'right',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 16,
                    color: 'secondary.text',
                  }}
                >
                  {totalBillingValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : billingDetail.agreementProduct != null ? (
    <Grid
      container
      sx={{
        m: 0,
        p: 0,
        mb: 1,
        backgroundColor: 'primary.main',
        borderRadius: 2,
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
        borderLeft: 8,
        borderRight: 3,
        borderColor: 'secondary.light',
      }}
    >
      <img
        src={MissingPhoto.BILLING_AGREEMENT_URL}
        style={{
          marginLeft: 5,
          width: 80,
          height: 80,
          borderRadius: 3,
          objectFit: 'scale-down',
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
        }}
        alt={'logo'}
      />

      <Grid item sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={sxHeader}>{'AGREEMENT'}</Typography>
          <Typography sx={{ ...sxHeader, pr: 2 }}>
            {' '}
            {dayjs(billingDetail.agreementProduct.lastBilled).format(
              'MMMM-YYYY'
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid
            item
            xs={1}
            sx={{
              width: 48,
              height: 48,
              backgroundColor: 'primary.light',
            }}
          >
            <img
              src={
                billingDetail.agreementProduct.product?.url ||
                MissingPhoto.PRODUCT_URL
              }
              style={{
                marginLeft: 5,
                width: 48,
                height: 48,
                borderRadius: 3,
                objectFit: 'scale-down',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
              }}
              alt={'logo'}
            />
          </Grid>
          <Grid item xs={11} sx={{}}>
            <Grid item xs={12} sx={{}}>
              <Typography sx={sxHeaderProduct}>
                {billingDetail.agreementProduct.product.name}
              </Typography>
            </Grid>
            <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
              <Grid item xs={8} sx={{}}>
                <Typography sx={sxHeaderFeature}>
                  {billingDetail?.agreementProduct?.product.productType.name}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography
                  sx={{
                    pl: 1,
                    pr: 2,
                    mt: -1,
                    width: '100&',
                    letterSpacing: 2,
                    textAlign: 'right',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 14,
                    color: 'primary.text',
                  }}
                >
                  {(billingDetail?.agreementProduct?.retailPrice).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  ) +
                    ' * ' +
                    billingDetail?.agreementProduct?.quantity}
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <Typography sx={sxHeaderFeature}>
                  {billingDetail?.agreementProduct?.product.productType.name}
                </Typography>
              </Grid>
              <Grid item sx={{ flexGrow: 1 }}>
                <Typography
                  sx={{
                    pl: 1,
                    pr: 2,
                    width: '100&',
                    letterSpacing: 2,
                    textAlign: 'right',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 16,
                    color: 'secondary.text',
                  }}
                >
                  {totalBillingValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    billingDetail.product != null ? (
      <Grid
        container
        sx={{
          m: 0,
          p: 0,
          mb: 1,
          backgroundColor: 'primary.main',
          borderRadius: 2,
          boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.50)',
          borderLeft: 8,
          borderRight: 3,
          borderColor: 'secondary.light',
        }}
      >
        <img
          src={MissingPhoto.BILLING_PRODUCT_URL}
          style={{
            marginLeft: 5,
            width: 80,
            height: 80,
            borderRadius: 3,
            objectFit: 'scale-down',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
          }}
          alt={'logo'}
        />
  
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sx={{}}>
            <Typography sx={sxHeader}>{'INVESTMENT'}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Grid
              item
              xs={1}
              sx={{
                width: 48,
                height: 48,
                backgroundColor: 'primary.light',
              }}
            >
              <img
                src={
                  billingDetail.product?.url ||
                  MissingPhoto.PRODUCT_URL
                }
                style={{
                  marginLeft: 5,
                  width: 48,
                  height: 48,
                  borderRadius: 3,
                  objectFit: 'scale-down',
                  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
                }}
                alt={'logo'}
              />
            </Grid>
            <Grid item xs={11} sx={{}}>
              <Grid item xs={12} sx={{}}>
                <Typography sx={sxHeaderProduct}>
                  {billingDetail.product.name}
                </Typography>
              </Grid>
              <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                <Grid item xs={8} sx={{}}>
                  <Typography sx={sxHeaderFeature}>
                    {billingDetail.product.productType.name}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{}}>
                  <Typography
                    sx={{
                      pl: 1,
                      pr: 2,
                      mt: -1,
                      width: '100&',
                      letterSpacing: 2,
                      textAlign: 'right',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      fontSize: 16,
                      color: 'primary.text',
                    }}
                  >
                    {'Total'}
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{}}>
                  <Typography sx={sxHeaderFeature}>
                    {billingDetail?.product.productType.name}
                  </Typography>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Typography
                    sx={{
                      pl: 1,
                      pr: 2,
                      width: '100&',
                      letterSpacing: 2,
                      textAlign: 'right',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      fontSize: 16,
                      color: 'secondary.text',
                    }}
                  >
                    {totalBillingValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <div>No data</div>
    )
  )
}
