import { useState, useReducer, useEffect } from 'react';
import useWindowsDimension from 'functions/useWindowsDimension';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import 'moment-timezone';

import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Grid,
  List,
  ListItem,
  IconButton,
  TextField,
  Accordion,
  AccordionDetails,
} from '@mui/material';

import { useParams } from 'react-router';
import { SearchFilter } from 'components/ui/SearchFilter';
import { ticketStatus } from 'data/enum';
import { ticketCommunicationStatus } from 'data/enum';
import { useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
//@ts-ignore
import DocumentUploader from 'components/ui/DocumentUploader';
//@ts-ignore
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import userAtom from 'data/atoms/userAtom';
import { SingleMessage } from 'components/ui/tickets/SingleMessage';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import {
  ITicketPostBillingViewModel,
  ITicketPostViewModel,
  ITicketViewModel,
} from '../../Interfaces/ITicketConfig';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  EnumObjectType,
  objectArray,
  IScheduleViewModel,
  EnumTicketStatus,
  EnumScheduleType,
} from 'Interfaces/IGlobalTypeConfig';
import { ScheduleView } from 'components/ui/ScheduleView';
import { useGetObjectList } from 'functions/useGetObjectList';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TextStyle, boxStyle, gridStyle } from 'data/FontStyle';
import { SwitchType } from 'components/ui/SwitchType';
import { GetWorkRole } from 'functions/GetWorkRole';
import { ConvertToEmployee } from 'functions/ConverToEmployee';
import { FormAccordionSummary } from 'components/form';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { EconomyView } from 'functions/EconomySimpleView';
import CalculateTotalTime from 'functions/CalculateTotalTime';
import { SingleTicketEmployeeView } from 'components/ui/SingleTicketEmployeeView';
import { ConvertToTicketPost } from 'functions/ConvertToTicketPost';
import _ from 'lodash';
import { bool, boolean } from 'yup';
import { BlockList } from 'net';
import { ConvertToPutTicketPost } from 'functions/ConvertToPutTicketPost';
import { TrendingUpTwoTone } from '@mui/icons-material';
import { findHours } from 'functions/findHours';
import { calculateTicketPostBilling } from 'functions/calculateTicketPostBilling';
import { checkForOvertime } from 'functions/checkForOvertime';

export interface ITicketCommunicationData {
  id: string | null;
  isSendMail: boolean;
  ticketPosts: ITicketPostViewModel[];
  ticketPost: ITicketPostViewModel;
  status: EnumTicketStatus;
  scheduleTime: IScheduleViewModel;
  ticket: ITicketViewModel;
  workRole: IWorkRoleViewModel | null;
  workRoles: IWorkRoleViewModel[];
  isInHouse: boolean;
  isBillable: boolean;
  overTime: boolean;
  internal: boolean;
  totalTime: string;
  totalValue: number;
  isTotalValueOpen: boolean;
  isTotalTimeOpen: boolean;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  comment: string;
  attachments: any[] | null;
  selectedDocuments: any | null;
  direction: number;
  ticketPostBilling: ITicketPostBillingViewModel | null;
  rates: IRateViewModel[];
  isShowOverTime: boolean;
  isEditable: boolean;
  isEmailAllowed: boolean;
}

const initialState: ITicketCommunicationData = {
  id: null,
  isSendMail: false,
  ticketPosts: [],
  ticketPost: {} as unknown as ITicketPostViewModel,
  status: EnumTicketStatus.New,
  scheduleTime: {
    id: '00000000-0000-0000-0000-000000000000',
    deliveryDate: dayjs(new Date()),
    scheduleType: 0,
    estimatedTime: {
      hours: 0,
      minutes: 0,
    },
  } as IScheduleViewModel,
  ticket: {} as unknown as ITicketViewModel,
  workRole: null,
  workRoles: [] as IWorkRoleViewModel[],
  isInHouse: true,
  isBillable: true,
  overTime: false,
  internal: false,
  totalTime: '00:00',
  totalValue: 0,
  isTotalValueOpen: false,
  isTotalTimeOpen: false,
  startDate: dayjs(new Date()).add(-15, 'minute'),
  endDate: dayjs(new Date()),
  comment: '',
  attachments: null,
  selectedDocuments: null,
  direction: 0,
  ticketPostBilling: null,
  rates: [],
  isShowOverTime: false,
  isEditable: true,
  isEmailAllowed: true,
};

type Action =
  | { type: 'IS_SEND_MAIL'; payload: boolean }
  | { type: 'SET_TICKET_POSTS'; payload: ITicketPostViewModel[] }
  | { type: 'SET_TICKET_POST'; payload: ITicketPostViewModel }
  | { type: 'REMOVE_TICKET_POST'; payload: ITicketCommunicationData }
  | { type: 'SET_STATUS'; payload: EnumTicketStatus }
  | { type: 'SET_SCHEDULE_TIME'; payload: IScheduleViewModel }
  | { type: 'SET_TICKET'; payload: ITicketViewModel }
  | { type: 'SET_WORK_ROLE'; payload: IWorkRoleViewModel }
  | { type: 'SET_WORK_ROLES'; payload: IWorkRoleViewModel[] }
  | { type: 'SET_IS_IN_HOUSE'; payload: boolean }
  | { type: 'SET_BILLABLE'; payload: boolean }
  | { type: 'SET_OVERTIME'; payload: boolean }
  | { type: 'SET_INTERNAL'; payload: boolean }
  | { type: 'SET_TOTAL_TIME'; payload: string }
  | { type: 'SET_TOTAL_TIME_OPEN'; payload: boolean }
  | { type: 'SET_TOTAL_VALUE'; payload: number }
  | { type: 'SET_TOTAL_VALUE_OPEN'; payload: boolean }
  | { type: 'SET_START_DATE'; payload: dayjs.Dayjs }
  | { type: 'SET_END_DATE'; payload: dayjs.Dayjs }
  | { type: 'SET_COMMENT'; payload: string }
  | { type: 'CHANGE_ATTACHMENTS'; payload: any }
  | { type: 'SET_SELECTED_DOCUMENTS'; payload: any }
  | { type: 'CHANGE_DIRECTION'; payload: number }
  | { type: 'SET_TICKET_POST_BILLING'; payload: ITicketPostBillingViewModel }
  | { type: 'SET_RATES'; payload: IRateViewModel[] }
  | { type: 'SET_SHOW_OVERTIME'; payload: boolean }
  | { type: 'EDIT_COMMENT'; payload: string }
  | { type: 'CHANGE_STATUS'; payload: any }
  | { type: 'SET_IS_EDITABLE'; payload: boolean }
  | { type: 'SET_IS_EMAIL_ALLOWED'; payload: boolean };

const reducer = (
  state: ITicketCommunicationData,
  action: Action
): ITicketCommunicationData => {
  switch (action.type) {
    case 'SET_IS_EMAIL_ALLOWED':
      return { ...state, isEmailAllowed: action.payload };
    case 'IS_SEND_MAIL':
      return { ...state, isSendMail: action.payload };
    case 'SET_TICKET_POSTS':
      console.log('ticketPostData', action.payload);

      return { ...state, ticketPosts: action.payload };
    case 'SET_TICKET_POST':
      // console.log('ticketPost', ticketPost);
      return {
        ...state,
        id: action.payload.id,
        ticketPost: action.payload,
        status: action.payload.status,
        startDate: dayjs(action.payload.startTime),
        endDate: dayjs(action.payload.endTime),
        comment: action.payload.message,
        attachments: action.payload.attachments,
        direction: action.payload.direction,
        isBillable: action.payload.billable,
        overTime: action.payload.overtime,
        internal: action.payload.internal,
        isInHouse: action.payload.isInHouse ?? true,
        isEditable: false,
        ticketPostBilling: action.payload.ticketPostBilling,
      };
    case 'REMOVE_TICKET_POST':
      return {
        ...state,
        ticketPost: {} as unknown as ITicketPostViewModel,
        status: EnumTicketStatus.New,
        startDate: dayjs(new Date()).add(-15, 'minute'),
        endDate: dayjs(new Date()),
        comment: '',
        attachments: null,
        direction: 0,
        isBillable: true,
        overTime: false,
        internal: false,
        isInHouse: true,
        isEditable: true,
        isSendMail: false,
      };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    case 'SET_SCHEDULE_TIME':
      return { ...state, scheduleTime: action.payload };
    case 'SET_TICKET':
      return { ...state, ticket: action.payload };
    case 'SET_WORK_ROLE':
      return { ...state, workRole: action.payload };
    case 'SET_WORK_ROLES':
      return { ...state, workRoles: action.payload };
    case 'SET_IS_IN_HOUSE':
      return { ...state, isInHouse: action.payload };
    case 'SET_BILLABLE':
      return { ...state, isBillable: action.payload };
    case 'SET_OVERTIME':
      return { ...state, overTime: action.payload };
    case 'SET_INTERNAL':
      return {
        ...state,
        internal: action.payload,
        isBillable: false,
        isSendMail: false,
      };
    case 'SET_TOTAL_TIME':
      return { ...state, totalTime: action.payload };
    case 'SET_TOTAL_TIME_OPEN':
      return { ...state, isTotalTimeOpen: action.payload };
    case 'SET_TOTAL_VALUE':
      return { ...state, totalValue: action.payload };
    case 'SET_TOTAL_VALUE_OPEN':
      return { ...state, isTotalValueOpen: action.payload };
    case 'SET_START_DATE':
      return { ...state, startDate: action.payload };
    case 'SET_END_DATE':
      return { ...state, endDate: action.payload };
    case 'SET_COMMENT':
      return { ...state, comment: action.payload };
    case 'CHANGE_ATTACHMENTS':
      return { ...state, attachments: action.payload };
    case 'SET_SELECTED_DOCUMENTS':
      return { ...state, selectedDocuments: action.payload };
    case 'CHANGE_DIRECTION':
      return { ...state, direction: action.payload };
    case 'SET_TICKET_POST_BILLING':
      let newTotalValue = 0;
      if (action.payload) {
        newTotalValue =
          action.payload.nightBillableValue +
          action.payload.workBillableValue +
          action.payload.midDayBillableValue +
          action.payload.eveningBillableValue;
      }
      return {
        ...state,
        ticketPostBilling: action.payload,
        totalValue: newTotalValue,
      };
    case 'SET_RATES':
      return { ...state, rates: action.payload };
    case 'SET_SHOW_OVERTIME':
      return { ...state, isShowOverTime: action.payload };
    case 'EDIT_COMMENT':
      if (state.status === EnumTicketStatus.New) {
        return {
          ...state,
          comment: state.comment + action.payload,
          status: EnumTicketStatus.InProgress,
        };
      } else {
        return { ...state, comment: action.payload };
      }
    case 'CHANGE_STATUS':
      return { ...state, status: action.payload };
    case 'SET_IS_EDITABLE':
      return { ...state, isEditable: action.payload };
    default:
      return state;
  }
};

export const TicketCommunications = () => {
  const { t } = useTranslation();
  /* INITIALIZATIONS */
  const [state, dispatch] = useReducer(reducer, initialState);

  const [owner] = useAtom(ownerAtom);
  const [user] = useAtom(userAtom);
  const { ticketId } = useParams<{ ticketId: string }>();
  const { height } = useWindowsDimension();

  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
  const [isListDisabled, setisListDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isObjectList, setIsObjectList] = useState<boolean>(true);

  let companyId = 'new';
  let employeeId = 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );

  // API CALLS

  let isEnabled = ticketId !== 'new' ? true : true;
  const { data: ticketData } = useGet(
    rq.ticket(ticketId || '', isEnabled, '0'),
    isEnabled
  );
  const postTicketPost = usePost(rqPost.ticketPost(ticketId || '', '0'));
  const putTicketPost = usePut(
    rqPost.ticketPostPut(ticketId || '', state?.id || '')
  );
  const postEmail = usePost(rqPost.sendEmail(true));

  const putTicket = usePut(
    rqPost.ticketResponsible(ticketId || '', isEnabled),
    isEnabled
  );
  const { data: ticketPostData } = useGet(rq.ticketPosts(ticketId || ''));
  isEnabled = owner.id ? true : false;

  const { data: workRoleData } = useGet(
    rq.workRoles(owner.id, isEnabled),
    isEnabled
  );

  isEnabled = state.rates.length <= 0 ? true : false;
  const { data: ratesData } = useGet(rq.rates(isEnabled));

  // HANDLES

  const handleAssigneMe = () => {
    let ticketResponsible = {
      ticketId: state.ticket.id,
      responsibleId: user.id,
    };
    putTicket.mutate(ticketResponsible);
    let ticket = _.cloneDeep(state.ticket);
    ticket.responsible = ConvertToEmployee(user);
    dispatch({
      type: 'SET_TICKET',
      payload: ticket,
    });
  };
  type FormOrMouseEvent =
    | React.FormEvent<HTMLFormElement>
    | React.MouseEvent<HTMLDivElement, MouseEvent>;

  const handleChange = (
    event: FormOrMouseEvent,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    if (!state.isEditable) return;
    switch (objectType) {
      case EnumObjectType.WorkRole:
        let object = selectedObject as IWorkRoleViewModel;
        dispatch({ type: 'SET_WORK_ROLE', payload: object });

        break;
      default:
        break;
    }
  };

  const handleChangeSchedule = (changeType: string, e: any) => {
    let scheduleTime = _.cloneDeep(state.scheduleTime);
    switch (changeType.toUpperCase()) {
      case 'DELIVERY_DATE':
        scheduleTime.deliveryDate = e;
        // Check if the deliveryDate is a Monday
        if (dayjs(scheduleTime.deliveryDate).day() === 1) {
          // Day.js treats Sunday as 0, Monday as 1, and so on.
          // Set startDate to the previous Friday at 07:00
          const startDateDayjs = dayjs(scheduleTime.deliveryDate)
            .subtract(3, 'day')
            .set('hour', 7)
            .set('minute', 0);
          scheduleTime.startDate = startDateDayjs;
        }
        break;
      case 'SCHEDULED_DATE':
        scheduleTime.startDate = e;
        scheduleTime.scheduleType = EnumScheduleType.Reminder;
        break;
      case 'SCHEDULE_TYPE':
        scheduleTime.scheduleType = e;
        break;
      case 'HOURS':
        scheduleTime.estimatedTime.hours = e;
        break;
      case 'MINUTES':
        scheduleTime.estimatedTime.minutes = e;
        break;
    }

    dispatch({
      type: 'SET_SCHEDULE_TIME',
      payload: scheduleTime,
    });
  };

  const handleSelectedPost = (ticketPost: ITicketPostViewModel) => {
    console.log('...handleSelectedPost, ticketPost: ', ticketPost);
    console.log('...handleSelectedPost, state', state);
    dispatch({
      type: 'SET_TICKET_POST',
      payload: ticketPost,
    });
    if (
      state.ticket.fixedHourlyRate === undefined ||
      state.ticket.fixedHourlyRate === null ||
      state.ticket.fixedHourlyRate === 0
    ) {
      if (state.ticket.company.workRole != null) {
        dispatch({
          type: 'SET_WORK_ROLE',
          payload: state.ticket.company.workRole,
        });
      } else if (user.workRole) {
        dispatch({
          type: 'SET_WORK_ROLE',
          payload: user.workRole,
        });
      }
    }
    // check if isBilled
    if (ticketPost.isBilled) {
      dispatch({
        type: 'SET_IS_EDITABLE',
        payload: false,
      });
    } else {
      dispatch({
        type: 'SET_IS_EDITABLE',
        payload: true,
      });
    }
    // check if mail is sendt
    if (ticketPost.isEmailSendt) {
      dispatch({
        type: 'SET_IS_EMAIL_ALLOWED',
        payload: false,
      });
    } else {
      dispatch({
        type: 'SET_IS_EMAIL_ALLOWED',
        payload: true,
      });
    }
    checkForOvertime(
      state.rates,
      state.startDate.hour(),
      state.startDate.minute()
    );
  };

  // USEEFFECTS

  /*   useEffect(() => {
    console.log('...state: ', state);
  }, [state]); */

  useEffect(() => {
    if (workRoleData) {
      dispatch({
        type: 'SET_WORK_ROLES',
        payload: workRoleData.workRoles,
      });
    }
  }, [workRoleData]);

  useEffect(() => {
    console.log('...selected documents', selectedDocuments);
    if (selectedDocuments.length > 0) {
      dispatch({
        type: 'CHANGE_ATTACHMENTS',
        payload: selectedDocuments,
      });
    }
  }, [selectedDocuments]);

  useEffect(() => {
    if (ratesData && ratesData.rates) {
      let rates = [...ratesData.rates];
      console.log('ratesData', ratesData);
      // Example: Let's say you want to increase the rate by 10 for each rate
      rates = rates.map((rate: IRateViewModel) => {
        rate.rateValue = 0;
        return rate;
      });
      dispatch({
        type: 'SET_RATES',
        payload: rates,
      });
    }
  }, [ratesData]);

  useEffect(() => {
    if (ticketPostData) {
      console.log('ticketPostData', ticketPostData);
      dispatch({
        type: 'SET_TICKET_POSTS',
        payload: ticketPostData.ticketPosts,
      });
      if (ticketPostData.workRole) {
        dispatch({
          type: 'SET_WORK_ROLE',
          payload: ticketPostData.workRole,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketPostData]);

  useEffect(() => {
    if (state.rates && state.rates.length > 0) {
      console.log('economy - ');
      dispatch({
        type: 'SET_TOTAL_TIME',
        payload: CalculateTotalTime(
          state.startDate,
          state.endDate,
          null,
          null
        ) as string,
      });
      const ticketPostBilling = calculateTicketPostBilling(
        state,
        state.rates,
        state.workRole,
        state.ticket.fixedHourlyRate
      );
      if (state.ticket.fixedHourlyRate === undefined) {
        state.ticket.fixedHourlyRate = 0;
      }
      console.log(
        'economy - ',
        ticketPostBilling,
        state.startDate,
        state.endDate,
        state.workRole,
        state.isBillable,
        state.overTime,
        state.isInHouse,
        state.rates,
        state.ticketPost
      );
      console.log('economy - fixedHourlyRate  ', state.ticket.fixedHourlyRate);

      dispatch({
        type: 'SET_TICKET_POST_BILLING',
        payload: ticketPostBilling,
      });

      //check if overtime
      if (state.rates) {
        let isShowOverTime = checkForOvertime(
          state.rates,
          state.startDate.hour(),
          state.startDate.minute()
        );
        dispatch({
          type: 'SET_SHOW_OVERTIME',
          payload: isShowOverTime,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.startDate,
    state.endDate,
    state.workRole,
    state.isBillable,
    state.overTime,
    state.isInHouse,
    state.rates,
  ]);

  useEffect(() => {
    if (ticketData) {
      dispatch({
        type: 'SET_TICKET',
        payload: ticketData,
      });
    }

    console.log('GetWorkRole state.ticket', state.ticket);
    let workRole = GetWorkRole(
      state.ticket?.company?.workRole ?? null,
      user.workRole,
      state.workRole
    );
    console.log('GetWorkRole w', workRole);
    if (workRole) {
      dispatch({
        type: 'SET_WORK_ROLE',
        payload: workRole,
      });
    }
  }, [ticketData, state.ticket]);

  useEffect(() => {
    // disable handleSelectTicketPost if it's new and it's being edited
    if (!state.ticketPost?.id && state.comment.length > 0) {
      console.log('...list of ticketPosts should now be unclickable');
      setisListDisabled(true);
    } else if (state.ticketPost?.id || state.comment.length === 0) {
      console.log('...list of ticketPosts is okay to click again');
      setisListDisabled(false);
    }
  }, [state.ticketPost.id, state.comment]);

  const onSubmit = async () => {
    // console.log('onSubmit : state', state);
    let _state = _.cloneDeep(state);
    if (state.ticketPost?.id) {
      console.log('onSubmit PUT state', _state);
      let ticketPost = ConvertToPutTicketPost(_state, user);

      console.log('onSubmit putTicketPost', ticketPost);
      putTicketPost.mutate(ticketPost, {
        onSuccess: (res: any) => {
          console.log('putTicketPost', res);
        },
      });
    } else {
      let ticketPost = ConvertToTicketPost(state, user);
      console.log('onSubmit postTicketPost', ticketPost);
      postTicketPost.mutate(ticketPost, {
        onSuccess: (res: any) => {
          console.log('postTicketPost', res);
        },
      });
    }
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {/* LEFT SIDE */}
      <Grid item xs={5} sx={{ pr: 0.5 }}>
        <SearchFilter
          Icon={TextsmsRoundedIcon}
          Header={t('Ticket.TicketCommunications.header')}
          filterValue={null}
          handleFilterChange={null}
          handleSearch={null}
          selectList={ticketStatus}
          gfx={
            <Button
              sx={{
                width: 30,
                height: 30,
                maxWidth: 30,
                maxHeight: 30,
                borderRadius: 3,
                backgroundColor: 'primary.main',
                color: 'primary.text',
              }}
              variant='contained'
              onClick={(e: any) => {
                e.preventDefault();
                dispatch({
                  type: 'REMOVE_TICKET_POST',
                  payload: initialState,
                });
              }}
            >
              {'+'}
            </Button>
          }
        />
        <List
          sx={{
            p: 1,
            height: Math.ceil(height - 256),
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {!!state.ticketPosts &&
            state.ticketPosts?.map(
              (ticketPost: ITicketPostViewModel, index: number) => (
                <Grid
                  sx={{
                    backgroundColor: 'transparent',
                    border: 0,
                    boxShadow: 'none',
                    filter: `${
                      isListDisabled ? 'brightness(0.8)' : 'brightness(1)'
                    } `,
                  }}
                  key={index}
                  onClick={() => {
                    if (!isListDisabled) {
                      handleSelectedPost(ticketPost);
                    }
                  }}
                >
                  <SingleMessage ticketPost={ticketPost} />
                </Grid>
              )
            )}
        </List>

        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>
      {/* RIGHT SIDE */}
      <Grid
        item
        xs={7}
        sx={{
          pl: 0.5,
          pr: 0.5,
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              pl: 0.5,
              pr: 0.5,
              position: 'relative',
              '::before': state.ticketPost
                .isBilled /* || state.ticketPost.isEmailSendt */
                ? {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(76, 101, 116, 0.475)',
                    zIndex: 10,
                    pointerEvents: 'none', // Prevent interaction
                    borderRadius: 3,
                  }
                : 'none',
            }}
          >
            {/* NEW COMMUNICATION*/}
            <SearchFilter
              Icon={TextsmsRoundedIcon}
              Header={
                !state.id
                  ? `${t('Ticket.TicketCommunications.newCommunication')}`
                  : `${t('Ticket.TicketCommunications.editCommunication')}`
              }
              gfx={
                <>
                  <Typography
                    sx={{
                      m: 0,
                      ml: 1,
                      pt: 1,
                      flexGrow: 1,
                      textTransform: 'uppercase',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                      fontSize: 14,
                      letterSpacing: 2,
                      position: 'absolute',
                      left: 260,
                    }}
                  >
                    {state.ticket.ticketNo}
                  </Typography>
                  {state.ticketPost
                    .isBilled /*  || state.ticketPost.isEmailSendt  */ ? (
                    <Typography
                      sx={{
                        zIndex: 11,
                        position: 'absolute',
                        left: 400,
                        color: 'error.main',
                      }}
                    >
                      {state.ticketPost.isBilled &&
                        t('Ticket.TicketCommunications.isBilled')}
                      {/*  {state.ticketPost.isEmailSendt &&
                      t('Ticket.TicketCommunications.isEmailSendt')} */}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </>
              }
              filterValue={state.status}
              handleFilterChange={(e: any) => {
                dispatch({
                  type: 'CHANGE_STATUS',
                  payload: e,
                });
              }}
              handleSearch={null}
              selectList={ticketCommunicationStatus}
            />

            <List
              sx={{
                height: Math.ceil(height - 310),
                width: '100%',
                mb: 0,
                p: 2,
                backgroundColor: 'primary.main',
                overflow: 'auto',
              }}
            >
              {state.scheduleTime &&
                (state.status === 1 ||
                  state.status === 3 ||
                  state.status === 4) && (
                  <Grid
                    sx={{
                      key: 'scheduleTime',
                      border: 1,
                      borderColor: 'primary.back',
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      p: 0,
                      mb: 2,
                    }}
                  >
                    <ScheduleView
                      scheduleTime={state.scheduleTime}
                      handleChange={handleChangeSchedule}
                    />
                  </Grid>
                )}
              <Grid container key={'container'} sx={{}}>
                {/* FIELDS - SWITCHES - MESSAGE - INTERNAL - MAILTO */}
                <Stack direction={'column'} key='stack' flex={1} sx={{}}>
                  {/* SWITCHES */}
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      mb: 1,
                    }}
                  >
                    <Grid item xs={7} sx={{ m: 0, p: 0, mb: 1 }}>
                      <Grid
                        container
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Grid item xs={7} sx={{ m: 0, p: 0, mb: 1 }}>
                          {state.ticket?.responsible ? (
                            <>
                              <SingleTicketEmployeeView
                                employee={state.ticket.responsible}
                                priority={0}
                              />
                            </>
                          ) : (
                            <Box sx={[boxStyle.base, { height: 60 }]}>
                              <Button
                                sx={{
                                  mx: 'auto',
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: 3,
                                }}
                                variant='contained'
                                onClick={(e) => {
                                  handleAssigneMe();
                                }}
                              >
                                <Typography sx={[TextStyle.text, {}]}>
                                  {t(
                                    'Ticket.TicketCommunications.assignCustomerEmployee'
                                  )}
                                </Typography>
                              </Button>
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={5} sx={{ m: 0, pl: 1, mb: 1 }}>
                          {state.ticket.fixedHourlyRate > 0 ? (
                            <Grid sx={[gridStyle.base, { height: 60 }]}>
                              <Typography sx={[TextStyle.textCenter, {}]}>
                                {t(
                                  'Ticket.TicketCommunications.fixedHourlyRate'
                                )}
                              </Typography>
                              <Typography
                                sx={[
                                  TextStyle.textCenter,
                                  {
                                    pt: 1,
                                    fontSize: 20,
                                    color: 'secondary.text',
                                  },
                                ]}
                              >
                                {state.ticket.fixedHourlyRate.toFixed(2)}
                              </Typography>
                            </Grid>
                          ) : state.workRole?.id ? (
                            <Grid sx={[gridStyle.base, { height: 60 }]}>
                              <Stack
                                direction='row'
                                spacing={0}
                                justifyContent={'space-between'}
                              >
                                {state.ticket?.company?.workRole ? (
                                  <Typography
                                    sx={[
                                      TextStyle.infoCenter,
                                      {
                                        pt: 0.5,
                                        fontSize: 12,
                                        letterSpacing: 2,
                                        color: 'primary.text',
                                      },
                                    ]}
                                  >
                                    {t('Ticket.TicketCommunications.support')}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={[
                                      TextStyle.infoCenter,
                                      {
                                        pt: 0.5,
                                        fontSize: 12,
                                        color: 'primary.text',
                                      },
                                    ]}
                                  >
                                    {state.workRole.title}
                                  </Typography>
                                )}

                                {state.ticket?.company?.workRole ? (
                                  <> </>
                                ) : (
                                  <ObjectDrawer
                                    buttonContext={t(
                                      'Ticket.TicketCommunications.selectWorkRole'
                                    )}
                                    buttonIcon={<ChangeCircleIcon />}
                                    objectList={
                                      !!objectDrawerList ? objectDrawerList : []
                                    }
                                    objectType={[EnumObjectType.WorkRole]}
                                    handleChange={handleChange}
                                    buttonHeight={30}
                                    buttonWidth={30}
                                  />
                                )}
                              </Stack>

                              {state.isInHouse ? (
                                <Typography
                                  sx={[
                                    TextStyle.textCenter,
                                    {
                                      pt: 0,
                                      fontSize: 18,
                                      color: 'secondary.text',
                                    },
                                  ]}
                                >
                                  {state.workRole.inHouseHourlyRate.toFixed(2)}
                                </Typography>
                              ) : (
                                <Typography
                                  sx={[
                                    TextStyle.textCenter,
                                    {
                                      pt: 0,
                                      fontSize: 18,
                                      color: 'secondary.text',
                                    },
                                  ]}
                                >
                                  {state.workRole.onSiteHourlyRate.toFixed(2)}
                                </Typography>
                              )}
                            </Grid>
                          ) : (
                            <ObjectDrawer
                              buttonContext={t(
                                'Ticket.TicketCommunications.selectWorkRole'
                              )}
                              buttonIcon={null}
                              objectList={
                                !!objectDrawerList ? objectDrawerList : []
                              }
                              objectType={[EnumObjectType.WorkRole]}
                              handleChange={handleChange}
                              buttonHeight={60}
                              buttonWidth={'100%'}
                            />
                          )}
                        </Grid>
                        <Grid item xs={4} sx={{ pr: 0.5 }}>
                          <SwitchType
                            isChecked={state.isBillable}
                            dispatch={dispatch}
                            type={'SET_BILLABLE'}
                            header={t('Ticket.TicketCommunications.Billable')}
                            showNotification={false}
                            disabled={state.internal || !state.isEditable}
                          />
                        </Grid>
                        <Grid item xs={4} sx={{ pl: 0.5 }}>
                          <SwitchType
                            isChecked={state.overTime}
                            dispatch={dispatch}
                            type={'SET_OVERTIME'}
                            header={t('Ticket.TicketCommunications.overtime')}
                            showNotification={state.isShowOverTime}
                            disabled={false || !state.isEditable}
                          />
                        </Grid>
                        <Grid item xs={4} sx={{ m: 0, pl: 1 }}>
                          <SwitchType
                            isChecked={state.isInHouse}
                            dispatch={dispatch}
                            type={'SET_IS_IN_HOUSE'}
                            header={t('Ticket.TicketCommunications.inHouse')}
                            showNotification={false}
                            disabled={false || !state.isEditable}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {/* BILLABLE TIME BUTTONS */}
                      <Grid container sx={{ m: 0, pl: 1 }}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            m: 0,
                            p: 0,
                            pl: 1,
                            pr: 1,
                            mt: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            borderRadius: 2,
                            height: 60,
                          }}
                        >
                          <Stack direction='row' spacing={0} flexGrow={1}>
                            <Typography
                              sx={[TextStyle.label, { width: '60%' }]}
                            >
                              {t('Ticket.TicketCommunications.dateTime')}
                            </Typography>
                            <Typography
                              sx={[TextStyle.value, { pr: 2, pt: 0.5 }]}
                            >
                              {state.totalTime}
                            </Typography>
                            {state.isTotalTimeOpen ? (
                              <IconButton
                                sx={{ m: 0, p: 0 }}
                                onClick={(e) => {
                                  dispatch({
                                    type: 'SET_TOTAL_TIME_OPEN',
                                    payload: false,
                                  });
                                }}
                              >
                                <KeyboardArrowUpIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                sx={{ m: 0, p: 0 }}
                                onClick={(e) => {
                                  dispatch({
                                    type: 'SET_TOTAL_TIME_OPEN',
                                    payload: true,
                                  });
                                  dispatch({
                                    type: 'SET_TOTAL_VALUE_OPEN',
                                    payload: false,
                                  });
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </IconButton>
                            )}
                          </Stack>
                          <Stack
                            sx={{}}
                            direction={'row'}
                            justifyContent='left'
                            alignItems='left'
                            width={280}
                            spacing={0.5}
                          >
                            {[15, 30, 45, 60].map((interval, index: number) => (
                              <Button
                                disabled={false || !state.isEditable}
                                sx={{ width: '20%', fontSize: 12, height: 26 }}
                                variant='contained'
                                key={index}
                                onClick={() =>
                                  dispatch({
                                    type: 'SET_START_DATE',
                                    payload: dayjs(state.startDate).add(
                                      -interval,
                                      'minute'
                                    ),
                                  })
                                }
                              >
                                {interval === 60 ? '+1:00' : `+0:${interval}`}
                              </Button>
                            ))}

                            {/* Reset Button */}
                            {true && (
                              <Button
                                disabled={false || !state.isEditable}
                                sx={{ width: '20%', fontSize: 12, height: 26 }}
                                variant='contained'
                                color='secondary'
                                onClick={() => {
                                  dispatch({
                                    type: 'SET_START_DATE',
                                    payload: dayjs(new Date()),
                                  });
                                  dispatch({
                                    type: 'SET_END_DATE',
                                    payload: dayjs(new Date()),
                                  });
                                }}
                              >
                                {t('Ticket.TicketCommunications.reset')}
                              </Button>
                            )}
                          </Stack>

                          {state.isTotalTimeOpen && (
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                width: '100%',
                                flexDirection: 'column',
                                backgroundColor: 'primary.main',
                                borderRadius: 2,
                                ml: 0,
                                mt: 0,
                                zIndex: 1000,
                                position: 'relative',
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  m: 0,
                                  p: 0,
                                  pl: 1,
                                  pr: 2,
                                  mt: 0,
                                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                }}
                              >
                                <Stack direction={'row'} sx={{}}>
                                  <Typography
                                    sx={{
                                      pt: 0.7,
                                      width: 100,
                                      letterSpacing: 1,
                                      textAlign: 'center',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      fontWeight: 'bold',
                                      textTransform: 'capitalize',
                                      fontSize: 14,
                                    }}
                                  >
                                    Start:
                                  </Typography>

                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DesktopDateTimePicker
                                      disabled={false || !state.isEditable}
                                      format='DD/MM/YYYY HH:mm'
                                      value={
                                        dayjs(state.startDate)
                                          ? state.startDate
                                          : dayjs(new Date())
                                      }
                                      onChange={(e) => {
                                        dispatch({
                                          type: 'SET_START_DATE',
                                          payload: dayjs(e),
                                        });
                                      }}
                                      ampm={false}
                                      views={[
                                        'year',
                                        'month',
                                        'day',
                                        'hours',
                                        'minutes',
                                      ]}
                                      slotProps={{
                                        textField: {
                                          sx: {
                                            '& .MuiInputBase-input': {
                                              fontSize: 14,
                                            },
                                            '& .MuiInputLabel-root': {
                                              fontSize: 14,
                                            },
                                          },
                                        } as any,
                                        openPickerIcon: {
                                          sx: {
                                            fontSize: 26,
                                          },
                                        } as any,
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Stack>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  m: 0,
                                  p: 0,
                                  pl: 1,
                                  pr: 2,
                                  mt: 0,
                                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                }}
                              >
                                <Stack direction={'row'} sx={{}}>
                                  <Typography
                                    sx={{
                                      pt: 0.7,
                                      width: 100,
                                      letterSpacing: 1,
                                      textAlign: 'center',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      fontWeight: 'bold',
                                      textTransform: 'capitalize',
                                      fontSize: 14,
                                    }}
                                  >
                                    {t('Ticket.TicketCommunications.end')}:
                                  </Typography>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DesktopDateTimePicker
                                      disabled={false || !state.isEditable}
                                      format='DD/MM/YYYY HH:mm'
                                      value={
                                        dayjs(state.endDate)
                                          ? state.endDate
                                          : null
                                      }
                                      onChange={(e) => {
                                        dispatch({
                                          type: 'SET_END_DATE',
                                          payload: dayjs(e),
                                        });
                                      }}
                                      ampm={false}
                                      views={[
                                        'year',
                                        'month',
                                        'day',
                                        'hours',
                                        'minutes',
                                      ]}
                                      slotProps={{
                                        textField: {
                                          sx: {
                                            '& .MuiInputBase-input': {
                                              fontSize: 14,
                                            },
                                            '& .MuiInputLabel-root': {
                                              fontSize: 14,
                                            },
                                          },
                                        } as any,
                                        openPickerIcon: {
                                          sx: {
                                            fontSize: 26,
                                          },
                                        } as any,
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Stack>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>

                        {/* ECONOMY VIEW */}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            m: 0,
                            p: 0,
                            pl: 1,
                            pr: 1,
                            mt: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            borderRadius: 2,
                            height: 60,
                          }}
                        >
                          <Stack direction='row' spacing={0} flexGrow={1}>
                            <Typography
                              sx={[TextStyle.label, { width: '60%' }]}
                            >
                              {t('Ticket.TicketCommunications.economy')}
                            </Typography>
                            <Typography
                              sx={[TextStyle.value, { pr: 2, pt: 0.5 }]}
                            >
                              {(state.totalValue || 0).toFixed(2)}
                            </Typography>
                            {state.isTotalValueOpen ? (
                              <IconButton
                                sx={{ m: 0, p: 0 }}
                                onClick={(e) => {
                                  dispatch({
                                    type: 'SET_TOTAL_VALUE_OPEN',
                                    payload: false,
                                  });
                                }}
                              >
                                <KeyboardArrowUpIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                sx={{ m: 0, p: 0 }}
                                onClick={(e) => {
                                  dispatch({
                                    type: 'SET_TOTAL_VALUE_OPEN',
                                    payload: true,
                                  });
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </IconButton>
                            )}
                          </Stack>

                          {state.isTotalValueOpen && (
                            <Grid item xs={12} sx={{ m: 0, p: 0 }}>
                              <EconomyView
                                ticketPostBilling={state.ticketPostBilling}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* MESSAGE */}
                  <Grid
                    container
                    spacing={0}
                    flexDirection={'row'}
                    sx={{ m: 0, p: 0, mt: 1 }}
                  >
                    <Grid item xs={12} sx={{ m: 0, pr: 0 }}>
                      <Grid
                        sx={{
                          flexGrow: 1,
                        }}
                      >
                        <Grid
                          sx={{
                            m: 0,
                            pb: 1,
                            flexGrow: 1,
                            backgroundColor: 'primary.back',
                            borderRadius: 2,
                          }}
                        >
                          <TextField
                            sx={{ m: 0, p: 0, backgroundColor: 'primary.main' }}
                            value={state.comment}
                            defaultValue={state.comment}
                            label={t(
                              'Ticket.TicketCommunications.internalTicket'
                            )}
                            variant='filled'
                            multiline={true}
                            disabled={false || !state.isEditable}
                            minRows={12}
                            maxRows={12}
                            onChange={(e) => {
                              dispatch({
                                type: 'EDIT_COMMENT',
                                payload: e.target.value,
                              });
                            }}
                          />

                          <Stack
                            direction='row'
                            spacing={0}
                            flexGrow={1}
                            sx={{ backgroundColor: 'rgb' }}
                          >
                            <Typography
                              sx={{
                                m: 0,
                                p: 0,
                                pl: 2,
                                letterSpacing: 1,
                                alignSelf: 'center',
                                alignContent: 'center',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textTransform: 'capitalize',
                                fontSize: 14,
                                flexGrow: 1,
                              }}
                            >
                              {t(
                                'Ticket.TicketCommunications.internalTicketMessage'
                              )}
                            </Typography>

                            <SwitchType
                              isChecked={state.internal}
                              dispatch={dispatch}
                              type={'SET_INTERNAL'}
                              header={null}
                              showNotification={false}
                              disabled={false || !state.isEditable}
                            />
                          </Stack>
                          <Stack
                            direction='row'
                            spacing={0}
                            flexGrow={1}
                            sx={{
                              backgroundColor:
                                state.status === 3 ||
                                state.status === 4 ||
                                state.status === 5 ||
                                state.status === 6
                                  ? 'success.dark'
                                  : 'transparent',
                              border: 2,
                              borderColor:
                                state.status === 3 ||
                                state.status === 4 ||
                                state.status === 5 ||
                                state.status === 6
                                  ? 'success.light'
                                  : 'transparent',
                              borderRadius: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                m: 0,
                                p: 0,
                                pl: 2,
                                letterSpacing: 1,
                                alignSelf: 'center',
                                alignContent: 'center',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textTransform: 'capitalize',
                                fontSize: 14,
                              }}
                            >
                              {t('Ticket.TicketCommunications.sendEmailTo')}:
                            </Typography>

                            <Typography
                              sx={{
                                m: 0,
                                p: 0,
                                pl: 2,
                                letterSpacing: 1,
                                alignSelf: 'center',
                                alignContent: 'left',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: 'secondary.text',
                                flexGrow: 1,
                              }}
                            >
                              {state?.ticket?.contact?.email}
                            </Typography>
                            <SwitchType
                              isChecked={state.isSendMail}
                              dispatch={dispatch}
                              type={'IS_SEND_MAIL'}
                              header={null}
                              showNotification={false}
                              disabled={
                                state.internal ||
                                !state.isEditable ||
                                !state.isEmailAllowed
                              }
                            />
                          </Stack>
                        </Grid>

                        <List>
                          {state.attachments?.map(
                            (document: any, index: number) => {
                              //console.log('document:', document);
                              return (
                                <ListItem key={index}>
                                  {document.url ? (
                                    <a
                                      rel='noopener noreferrer'
                                      target='_blank'
                                      href={document.url}
                                      style={{ color: 'white' }}
                                    >
                                      {document.fileName}
                                    </a>
                                  ) : (
                                    document.file.name
                                  )}
                                </ListItem>
                              );
                            }
                          )}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={{ pr: 0.5, pl: 0.5, direction: 'row' }}>
                <Accordion
                  disableGutters
                  sx={{
                    backgroundColor: 'transparent',
                    border: 0,
                    m: 1,
                  }}
                >
                  <FormAccordionSummary
                    Icon={AttachFileIcon}
                    title={t('Ticket.TicketCommunications.attachments')}
                  />
                  <AccordionDetails>
                    <DocumentUploader
                      setDocuments={(files: any) =>
                        setSelectedDocuments((prevDocs: any) => [
                          ...prevDocs,
                          ...files,
                        ])
                      }
                    />
                    <List>
                      {state.attachments?.map(
                        (document: any, index: number) => {
                          //console.log('document:', document);
                          return (
                            <ListItem key={index}>
                              {document.url ? (
                                <a
                                  rel='noopener noreferrer'
                                  target='_blank'
                                  href={document.url}
                                  style={{ color: 'white' }}
                                >
                                  {document.fileName}
                                </a>
                              ) : (
                                document.file.name
                              )}
                            </ListItem>
                          );
                        }
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </List>
            {/* FOTTER */}
            {/* SUBMIT*/}
            <Paper
              variant='elevation3'
              sx={{
                p: 2,
                borderRadius: 2,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <Stack direction={'column'}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  {state?.ticketPost?.id !== null && (
                    <Button
                      sx={{
                        width: '25%',
                        backgroundColor: 'primary.back',
                      }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        dispatch({
                          type: 'REMOVE_TICKET_POST',
                          payload: initialState,
                        });
                      }}
                      variant='contained'
                    >
                      {t('Ticket.TicketCommunications.Cancel')}
                    </Button>
                  )}
                  <Button
                    disabled={state.comment.length <= 0 || !state.isEditable}
                    sx={{
                      width: '25%',
                      // backgroundColor: 'secondary.main',
                    }}
                    type='submit'
                    onClick={(e: any) => {
                      e.preventDefault();
                      onSubmit();
                    }}
                    id={
                      !state.id
                        ? `${t('Ticket.TicketCommunications.submit')}`
                        : `${t('Ticket.TicketCommunications.confirmEdit')}`
                    }
                    variant='contained'
                  >
                    {!state.id
                      ? `${t('Ticket.TicketCommunications.submit')}`
                      : `${t('Ticket.TicketCommunications.confirmEdit')}`}
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
